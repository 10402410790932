import { useEffect, useState } from "react";
import { CertificationStatusENUM } from "../../utils/enum";
import {
  extractCertificationCodeFromURL,
  formatStringToTitle,
  remove0,
} from "../../utils/utils";
import DownloadFile from "../certification-process/DownloadFile";
import UpdateCertifications from "./UpdateCertifications";
import { Link } from "react-router-dom";

export default function TransactionDetails({
  certificationData,
  isAdmin = false,
  userData = null,
  closeModal,
}) {
  const certificationDatas = certificationData?.formData
    ? certificationData?.formData
    : certificationData;

  const user = JSON.parse(localStorage.getItem("user"));

  const [data, setData] = useState(user?.data);

  useEffect(() => {
    if (userData) setData(userData);
  }, [userData]);

  const fieldToDisplay = [];

  const orderFieldCommodity = [
    "serviceName",
    "estimatedCommodityVolumePerQuantity",
    "unit",
    "estimatedFirstTransDate",
    "numberOfTranscations",
    "origin",
    "destination",
    "preTransactionCertification",
    "cradleToGate",
    "transactionDescription",
  ];

  const orderFieldProduct = [
    "serviceName",
    "estimatedCommodityVolumePerQuantity",
    "unit",
    "estimatedFirstTransDate",
    "numberOfTranscations",
    "origin",
    "destination",
    "preTransactionCertification",
    "transactionDescription",
  ];

  const orderFieldServiceOther = [
    "transactionDescription",
    "shippingDate",
    "contractTimePeriod",
    "preTransactionCertification",
  ];

  const orderFieldServiceVoyage = [
    "isBallastVoyage",
    "ballastVoyageDescription",
    "vesselType",
    "vesselName",
    "typeOfFuel",
    "fuelConsumed",
    "freightDescription",
    "numberOfTranscations",
    "shippingDate",
    "origin",
    "destination",
    "preTransactionCertification",
    "transactionDescription",
  ];

  const orderFieldServiceFlight = [
    "numberOfTranscations",
    "shippingDate",
    "origin",
    "arrival",
    "airCraftType",
    "preTransactionCertification",
    "transactionDescription",
  ];

  const orderFieldServiceTimeChartering = [
    "vesselType",
    "vesselName",
    "contractTimePeriod",
    "typeOfFuel",
    "fuelConsumed",
    "transactionDescription",
  ];

  const orderFieldServiceTimeFlight = [
    "contractTimePeriod",
    "airCraftType",
    "preTransactionCertification",
    "transactionDescription",
  ];

  const secondStepField = (data) => {
    if (!data.ableToCalculateCF) {
      if (!data.hasCompanyContractedCalculation) {
        return [
          "ableToCalculateCF",
          "hasCompanyContractedCalculation",
          "requireServicesToCalculateCF",
        ];
      }
      return [
        "ableToCalculateCF",
        "hasCompanyContractedCalculation",
        "nameOfServiceProvideCFC",
        "contactAssuranceProvider",
        "emailOfServiceProvider",
      ];
    }
    return ["ableToCalculateCF"];
  };

  const thirdStepField = (data) => {
    if (!data.wantTobeIntroToVeritas) {
      if (data.hasPreferenceForAnotherAssuranceProvider) {
        return [
          "wantTobeIntroToVeritas",
          "hasPreferenceForAnotherAssuranceProvider",
          "anotherNameOfAssuranceProvider",
          "anotherContactOfAssuranceProvider",
          "anotherEmailOfAssuranceProvider",
          "canCOCManagementContatctTheAssuranceProvider",
        ];
      }
      return [
        "wantTobeIntroToVeritas",
        "hasPreferenceForAnotherAssuranceProvider",
      ];
    }
    return ["wantTobeIntroToVeritas"];
  };

  const fourthStep = () => {
    return ["needSupportToAccessVoluntaryCarbonMarkets"];
  };

  const getCommonFields = (data) => {
    const secondFields = secondStepField(data);
    const thirdFields = thirdStepField(data);
    const fourthField = fourthStep();
    return secondFields.concat(thirdFields).concat(fourthField);
  };

  const getNameByCertificationType = (field) => {
    if (field === "serviceName") {
      switch (certificationDatas.certificationType) {
        case 1:
          return "Commodity";
        case 2:
          return "Product";
        case 3:
          return "Service";
        default:
          return;
      }
    }
    if (field === "numberOfTranscations") {
      if (
        certificationDatas.airCraftType &&
        certificationDatas.certificationType === 3
      ) {
        return formatStringToTitle(
          "Estimated number of flights to be certified"
        );
      } else if (
        !certificationDatas.airCraftType &&
        certificationDatas.certificationType === 3
      ) {
        return "Number Of Voyages To Be Certified";
      } else {
        return "Number Of Transaction(s)";
      }
    }
    if (field === "transactionDescription") {
      switch (certificationDatas.certificationType) {
        case 3:
          return certificationDatas.airCraftType
            ? "Additional information"
            : "Service Description";
        default:
          return "Transaction Description";
      }
    }
    if (field === "estimatedFirstTransDate") {
      switch (certificationDatas.certificationType) {
        case 3:
          return certificationDatas.contractTimePeriod &&
            certificationDatas.estimatedFirstTransDate
            ? "Date Of Service"
            : "Date";
        default:
          return "Estimated (First) Transaction(s) Date";
      }
    }
    if (field === "preTransactionCertification") {
      return "Certification";
    }
    if (field === "cradleToGate") {
      return "Boundaries";
    }
    if (field === "estimatedCommodityVolumePerQuantity") {
      switch (certificationDatas.certificationType) {
        case 1:
          return "Estimated Commodity Volume/Quantity";
        case 2:
          return "Estimated Product Volume/Quantity";
        default:
          return;
      }
    }
  };

  const reformatField = (field) => {
    switch (field) {
      case "destination":
        return "Destination (Gate or Grave)";
      case "canCOCManagementContatctTheAssuranceProvider":
        return formatStringToTitle(
          "Can COC contact the Verification Body (VVB) ?"
        );
      case "anotherEmailOfAssuranceProvider":
        return formatStringToTitle("email of Verification Body (VVB)");
      case "anotherContactOfAssuranceProvider":
        return formatStringToTitle("contact of Verification Body (VVB)");
      case "anotherNameOfAssuranceProvider":
        return formatStringToTitle("name of Verification Body (VVB)");
      case "ableToCalculateCF":
        return formatStringToTitle("able to calculate carbon footprint");
      case "serviceProvideCFC":
        return formatStringToTitle(
          "service provider of carbon footprint calculation"
        );
      case "requireServicesToCalculateCF":
        return formatStringToTitle(
          "Does the company require services to calculate carbon footprint?"
        );
      case "wantTobeIntroToVeritas":
        return formatStringToTitle("need Introduction to Bureau Veritas?");
      case "nameOfServiceProvideCFC":
        return formatStringToTitle("name of MRV");
      case "estimatedFirstTransDate":
        return formatStringToTitle("estimated first transaction date");
      case "shippingDate":
        return certificationDatas.airCraftType
          ? "First Flight(s) Date"
          : certificationDatas?.contractTimePeriod
          ? "Date Of Service"
          : formatStringToTitle("First Voyage Date");
      case "contactAssuranceProvider":
        return formatStringToTitle("contact MRV");
      case "estimatedCommodityVolumePerQuantity":
        return certificationDatas.certificationType === 1
          ? formatStringToTitle("Estimated Commodity Volume/Quantity")
          : formatStringToTitle("Estimated Product Volume/Quantity");
      case "hasCompanyContractedCalculation":
        return formatStringToTitle(
          "Has contracted a MRV (carbon footprint calculation) ?"
        );
      case "contractTimePeriod":
        return certificationDatas.airCraftType
          ? formatStringToTitle("Certification")
          : formatStringToTitle("Contract time period");
      case "isBallastVoyage":
        return "Ballast Voyage(s)";
      case "vesselName":
        return certificationDatas.contractTimePeriod
          ? formatStringToTitle("Name of vessel")
          : formatStringToTitle("Vessel Name and IMO Number");
      case "fuelConsumed":
        return "Consumed Fuel Quantity (estimated or actual)";
      case "ballastVoyageDescription":
        return "Ballast voyage(s) description";
      case "arrival":
        return formatStringToTitle("Destination");
      case "typeOfFuel":
        return formatStringToTitle("Fuel Type");
      case "emailOfServiceProvider":
        return formatStringToTitle("Email of MRV");
      default:
        return formatStringToTitle(field);
    }
  };

  const displayValues = (datas, displayFields) => {
    if (displayFields) {
      for (const field of displayFields) {
        const value = datas[field];

        if (
          value !== null &&
          value !== "" &&
          value !== "1970-01-01T00:00:00Z" &&
          value !== "1970-01-01" &&
          value !== "1970-01-01 to 1970-01-01" &&
          value !== "undefined"
        ) {
          let valueToDisplay = value;
          if (typeof value === "boolean") {
            valueToDisplay = value ? "Yes" : "No";
          }
          if (field === "estimatedFirstTransDate" || field === "shippingDate") {
            valueToDisplay = new Date(value).toLocaleDateString();
          }
          if (field === "preTransactionCertification") {
            valueToDisplay = value
              ? "Pre-Transaction Certification"
              : "Post-Transaction Certification";
          }
          if (field === "cradleToGate") {
            valueToDisplay = value ? "Cradle To Gate" : "Cradle To Grave";
          }
          if (field === "contractTimePeriod") {
            const fromDate = value && value.split(" to ")[0];
            const toDate = value && value.split(" to ")[1];
            valueToDisplay = `From ${new Date(
              fromDate
            ).toLocaleDateString()} to ${new Date(
              toDate
            ).toLocaleDateString()}`;
          }
          fieldToDisplay.push(
            <div key={field} className="flex justify-between py-2">
              <span className="text-coc-blue">
                {field === "serviceName" ||
                field === "numberOfTranscations" ||
                field === "transactionDescription" ||
                field === "estimatedFirstTransDate" ||
                field === "preTransactionCertification" ||
                field === "cradleToGate"
                  ? getNameByCertificationType(field)
                  : reformatField(field)}
                :
              </span>
              <span
                style={{
                  wordBreak: "initial",
                  textAlign: "left",
                }}
                className="text-black"
              >
                {valueToDisplay}
              </span>
            </div>
          );
        }
      }
    }
  };

  const commonOrderField = getCommonFields(certificationDatas);

  let allFields;
  if (certificationDatas.certificationType === 1) {
    allFields = orderFieldCommodity.concat(commonOrderField);
  } else if (certificationDatas.certificationType === 2) {
    allFields = orderFieldProduct.concat(commonOrderField);
  } else if (certificationDatas.certificationType === 3) {
    if (certificationDatas.ballastVoyageDescription) {
      if (!certificationDatas.isBallastVoyage) {
        const index = orderFieldServiceVoyage.indexOf(
          "ballastVoyageDescription"
        );
        if (index !== -1) {
          orderFieldServiceVoyage.splice(index, 1);
        }
      }
      allFields = orderFieldServiceVoyage.concat(commonOrderField);
    } else if (
      (certificationDatas.shippingDate &&
        certificationDatas.transactionDescription &&
        !certificationDatas.vesselName &&
        !certificationDatas.airCraftType) ||
      (certificationDatas.contractTimePeriod &&
        certificationDatas.transactionDescription &&
        !certificationDatas.vesselName &&
        !certificationDatas.airCraftType)
    ) {
      allFields = orderFieldServiceOther.concat(commonOrderField);
    } else if (
      certificationDatas.contractTimePeriod &&
      certificationDatas.vesselName
    ) {
      allFields = orderFieldServiceTimeChartering.concat(commonOrderField);
    } else if (
      certificationDatas.airCraftType &&
      !certificationDatas.contractTimePeriod
    ) {
      allFields = orderFieldServiceFlight.concat(commonOrderField);
    } else if (
      certificationDatas.airCraftType &&
      certificationDatas.contractTimePeriod
    ) {
      allFields = orderFieldServiceTimeFlight.concat(commonOrderField);
    }
  }

  displayValues(certificationDatas, allFields);

  const details =
    isAdmin &&
    certificationDatas.certificationStatus !==
      CertificationStatusENUM.COMPLETED ? (
      <UpdateCertifications
        certificationsData={certificationDatas}
        closeModal={closeModal}
      />
    ) : (
      <>
        <div className="recap-transaction">
          <h2 className="ml-5 text-coc-blue font-weight-bold">
            {certificationDatas?.name ? certificationDatas?.name : ""}
          </h2>
          <h2 className="text-center text-lg text-coc-blue font-weight-bold mb-16">
            Application Information
          </h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="px-5">
              <div className="flex justify-between py-2">
                <span className="text-coc-blue capitalize">First Name:</span>
                <span className="text-black">{data?.firstName}</span>
              </div>
              <div className="flex justify-between py-2">
                <span className="text-coc-blue capitalize">Last Name:</span>
                <span className="text-black">{data?.lastName}</span>
              </div>
            </div>
            <div className="px-5">
              <div className="flex justify-between py-2">
                <span className="text-coc-blue capitalize">Company Name:</span>
                <span className="text-black">{data?.companyName}</span>
              </div>
              <div className="flex justify-between py-2">
                <span className="text-coc-blue capitalize">Email:</span>
                <span className="text-black">{data?.email}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 recap-transaction">
          <div className="px-5">
            {fieldToDisplay.slice(0, Math.ceil(fieldToDisplay.length / 2))}
          </div>
          <div className="px-5">
            {fieldToDisplay.slice(Math.ceil(fieldToDisplay.length / 2))}
          </div>
        </div>
        {certificationDatas?.creditRetirement && (
          <>
            <h2 className="py-9 text-center text-lg text-coc-blue font-weight-bold">
              Credit Retirement
            </h2>
            <div className="grid 2xl:grid-cols-2 2xl:px-5 recap-transaction">
              <div style={{ lineHeight: 1.2 }} className="flex justify-between">
                <div className="flex">
                  <h3 className="text-coc-blue font-medium capitalize">
                    Transaction carbon footprint :{" "}
                  </h3>{" "}
                  <span className="text-black font-medium ml-2">
                    {
                      certificationDatas?.creditRetirement[0]
                        .transactionCarbonFootPrint
                    }
                  </span>
                </div>

                <div className="flex">
                  <h3 className="text-coc-blue font-medium capitalize">
                    Retired Carbon Credits :{" "}
                  </h3>{" "}
                  <span className="text-black font-medium ml-2">
                    {
                      certificationDatas?.creditRetirement[0]
                        .transactionToBeRetired
                    }
                  </span>
                </div>
              </div>
            </div>

            <div className="rounded-lg 2xl:px-5">
              <table className="min-w-full border-collapse block md:table mt-4 border-1 rounded-md px-2 overflow-hidden">
                <thead className="bg-coc-blue h-[70px]">
                  <tr className="border-t border-b md:hidden">
                    <th className="py-6" colSpan="2">
                      Credit Retirement
                    </th>
                  </tr>
                  <tr className="text-gray-600 text-sm md:text-xs lg:text-sm tracking-wider text-center">
                    <th className="text-white font-light">Standard</th>
                    <th className="text-white font-light">Vintage Year</th>
                    <th className=" text-white font-light">Serial Number</th>
                    <th className=" text-white font-light">Quantity</th>
                    <th className=" text-white font-light">Project ID</th>
                    <th className=" text-white font-light">
                      Project Description and Location
                    </th>
                    <th className="text-white font-light">Retirement Date</th>
                    <th className="text-white font-light pr-3">
                      Proof of Retirement
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm md:text-xs lg:text-sm">
                  {certificationDatas?.creditRetirement.map(
                    (element, index) => {
                      return (
                        <tr key={index} className="border-b text-center">
                          <td className="py-3 text-black  pl-2">
                            {element.standard}
                          </td>
                          <td className="py-3 text-black text-center">
                            {new Date(element.vintageYear).getFullYear()}
                          </td>
                          <td className="py-3 text-black text-center">
                            {element.serialNumbers}
                          </td>
                          <td className="py-3 text-black text-center">
                            {element.quantity}
                          </td>
                          <td className="py-3 text-black text-center">
                            {element.idRetirement}
                          </td>
                          <td className="py-3 text-black text-center">
                            {element.projectDescriptionAndLocation}
                          </td>
                          <td className="py-3 text-black text-center">
                            {new Date(
                              element.retirementDate
                            ).toLocaleDateString()}
                          </td>
                          <td className="py-3 text-black text-center">
                            {element.proofOfRetirement !== null &&
                              element.proofOfRetirement !== "null" && (
                                <Link
                                  to={`${
                                    element.proofOfRetirement.includes(
                                      "https://"
                                    )
                                      ? element.proofOfRetirement
                                      : `https://${element.proofOfRetirement}`
                                  }`}
                                >
                                  {element.proofOfRetirement}
                                </Link>
                              )}
                            <br />
                            {element.attachments.length > 0 &&
                              element.attachments.map((file) => {
                                return <DownloadFile file={file} />;
                              })}
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
        {certificationDatas?.urlCertificationForQRCode &&
          user?.data.userRole === "COC" && (
            <div className="mt-5 ">
              <span className="text-coc-blue">
                Link to download the certificate:{" "}
              </span>
              <br />
              <Link
                to={certificationDatas?.urlCertificationForQRCode}
                target="_blank"
              >
                {certificationDatas?.urlCertificationForQRCode}
              </Link>
            </div>
          )}
      </>
    );

  return details;
}
