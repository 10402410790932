import { React } from "react";
import RadioInput from "../../../components/forms/RadioInput";
import TextInput from "../../../components/forms/TextInput";
import Error from "../../../components/Alert/Error";
import { fieldRequiredMessage } from "../../../errors/errors";
import EmailInput from "../../../components/forms/EmailInput";

const ThirdStep = ({
  formData,
  setFormData,
  errors,
  errorCount,
  register,
  setValue,
  unregister,
}) => {
  const handleChangeInput = (fieldName, value, fieldsToUnregister) => {
    setFormData({ ...formData, [fieldName]: value });
    setValue(fieldName, value);

    // Mettre à jour les champs à désenregistrer
    if (fieldsToUnregister) {
      // Désenregistrer les champs
      for (const field of fieldsToUnregister) {
        unregister(field);
      }
    }
  };

  return (
    <div className="w-11/12 p-4">
      <p className="">
        As part of the certification process the carbon footprint calculation
        shall be verified by an independent Verification Body (VVB).
      </p>
      <p className="">
        Carbon Offset Certification recommends{" "}
        <span className="text-coc-blue font-medium">
          <a
            className="hover:text-coc-blue"
            target="_blank"
            href="https://group.bureauveritas.com/markets-services/commodities"
            rel="noreferrer"
          >
            Bureau Veritas
          </a>
        </span>{" "}
        as preferred Verification Body (VVB).
        <br /> But a verification report from any recognised Verification Body
        approved by COC can be accepted. Please check COC partners list or ask
        COC team for accreditation of a new Verification Body:
      </p>

      <div className="flex items-center assurance mt-3">
        <p className="mr-4 w-1/2">
          Does the company want to be introduced to Bureau Veritas?{" "}
        </p>

        <div className="inline-flex">
          <RadioInput
            onChange={(e) =>
              handleChangeInput("wantTobeIntroToVeritas", true, [
                "hasPreferenceForAnotherAssuranceProvider",
                "anotherNameOfAssuranceProvider",
                "anotherContactOfAssuranceProvider",
                "anotherEmailOfAssuranceProvider",
                "canCOCManagementContatctTheAssuranceProvider",
              ])
            }
            register={{
              ...register("wantTobeIntroToVeritas", { required: true }),
            }}
            checked={formData.wantTobeIntroToVeritas === true}
            value={true}
            name="wantTobeIntroToVeritas"
          />
          <label className="capitalize text-sm" htmlFor="">
            Yes
          </label>
        </div>

        <div className="inline-flex ml-4">
          <RadioInput
            onChange={(e) => handleChangeInput("wantTobeIntroToVeritas", false)}
            register={{
              ...register("wantTobeIntroToVeritas", { required: true }),
            }}
            checked={formData.wantTobeIntroToVeritas === false}
            value={false}
            name="wantTobeIntroToVeritas"
          />
          <label className="capitalize text-sm" htmlFor="">
            No
          </label>
        </div>
      </div>
      {errors.wantTobeIntroToVeritas &&
        errors.wantTobeIntroToVeritas.type === "required" && (
          <Error key={errorCount} text={fieldRequiredMessage("This field")} />
        )}
      {JSON.parse(formData.wantTobeIntroToVeritas) === false && (
        <>
          <div className="flex items-center assurance mt-3">
            <p className="mr-4 w-1/2">
              Has the company a preference for another Verification Body (VVB)?
            </p>

            <div className="inline-flex">
              <RadioInput
                onChange={(e) =>
                  handleChangeInput(
                    "hasPreferenceForAnotherAssuranceProvider",
                    true
                  )
                }
                register={{
                  ...register("hasPreferenceForAnotherAssuranceProvider", {
                    required: true,
                  }),
                }}
                checked={formData.hasPreferenceForAnotherAssuranceProvider}
                value={true}
                name="hasPreferenceForAnotherAssuranceProvider"
              />
              <label className="capitalize text-sm" htmlFor="">
                Yes
              </label>
            </div>

            <div className="inline-flex ml-4">
              <RadioInput
                onChange={(e) =>
                  handleChangeInput(
                    "hasPreferenceForAnotherAssuranceProvider",
                    false,
                    [
                      "anotherNameOfAssuranceProvider",
                      "anotherContactOfAssuranceProvider",
                      "anotherEmailOfAssuranceProvider",
                      "canCOCManagementContatctTheAssuranceProvider",
                    ]
                  )
                }
                register={{
                  ...register("hasPreferenceForAnotherAssuranceProvider", {
                    required: true,
                  }),
                }}
                checked={
                  formData.hasPreferenceForAnotherAssuranceProvider === false
                }
                value={false}
                name="hasPreferenceForAnotherAssuranceProvider"
              />
              <label className="capitalize text-sm" htmlFor="">
                No
              </label>
            </div>
          </div>
          {errors.hasPreferenceForAnotherAssuranceProvider &&
            errors.hasPreferenceForAnotherAssuranceProvider.type ===
              "required" && (
              <Error
                key={errorCount}
                text={fieldRequiredMessage("This field")}
              />
            )}

          {JSON.parse(formData.hasPreferenceForAnotherAssuranceProvider) ===
            true && (
            <div className="flex w-full justify-between mt-3">
              <div className="col pl-0">
                <TextInput
                  onChange={(e) =>
                    handleChangeInput(
                      "anotherNameOfAssuranceProvider",
                      e.target.value
                    )
                  }
                  register={{
                    ...register("anotherNameOfAssuranceProvider", {
                      required: true,
                    }),
                  }}
                  name="anotherNameOfAssuranceProvider"
                  value={formData.anotherNameOfAssuranceProvider}
                  label="Verification Body (VVB)"
                  placeholder="Company"
                />
                {errors.anotherNameOfAssuranceProvider &&
                  errors.anotherNameOfAssuranceProvider.type === "required" && (
                    <Error
                      key={errorCount}
                      text={fieldRequiredMessage("Company Name")}
                    />
                  )}
              </div>

              <div className="col">
                <TextInput
                  onChange={(e) =>
                    handleChangeInput(
                      "anotherContactOfAssuranceProvider",
                      e.target.value
                    )
                  }
                  register={{
                    ...register("anotherContactOfAssuranceProvider", {
                      required: true,
                    }),
                  }}
                  name="anotherContactOfAssuranceProvider"
                  value={formData.anotherContactOfAssuranceProvider}
                  label="Point of contact"
                  placeholder="Name"
                />
                {errors.anotherContactOfAssuranceProvider &&
                  errors.anotherContactOfAssuranceProvider.type ===
                    "required" && (
                    <Error
                      key={errorCount}
                      text={fieldRequiredMessage("Contact Name")}
                    />
                  )}
              </div>

              <div className="col mt-2">
                <EmailInput
                  onChange={(e) =>
                    handleChangeInput(
                      "anotherEmailOfAssuranceProvider",
                      e.target.value
                    )
                  }
                  register={{
                    ...register("anotherEmailOfAssuranceProvider", {
                      required: true,
                    }),
                  }}
                  name="anotherEmailOfAssuranceProvider"
                  value={formData.anotherEmailOfAssuranceProvider}
                  label="Email-invisible"
                  placeholder="Email"
                />
                {errors.anotherContactOfAssuranceProvider &&
                  errors.anotherContactOfAssuranceProvider.type ===
                    "required" && (
                    <Error
                      key={errorCount}
                      text={fieldRequiredMessage("Contact Email")}
                    />
                  )}
              </div>
            </div>
          )}

          {JSON.parse(formData.hasPreferenceForAnotherAssuranceProvider) ===
            true && (
            <div className="flex items-center assurance mt-4">
              <p className="mr-4 w-1/2">
                Can COC management contact this Verification Body (VVB) to
                facilitate verification process and explain Carbon Offset
                Certification protocol requirements?
              </p>

              <div className="inline-flex">
                <RadioInput
                  onChange={(e) =>
                    handleChangeInput(
                      "canCOCManagementContatctTheAssuranceProvider",
                      true
                    )
                  }
                  register={{
                    ...register(
                      "canCOCManagementContatctTheAssuranceProvider",
                      { required: true }
                    ),
                  }}
                  checked={
                    formData.canCOCManagementContatctTheAssuranceProvider ===
                    true
                  }
                  value={true}
                  name="canCOCManagementContatctTheAssuranceProvider"
                />
                <label className="capitalize text-sm" htmlFor="">
                  Yes
                </label>
              </div>

              <div className="inline-flex ml-4">
                <RadioInput
                  onChange={(e) =>
                    handleChangeInput(
                      "canCOCManagementContatctTheAssuranceProvider",
                      false
                    )
                  }
                  register={{
                    ...register(
                      "canCOCManagementContatctTheAssuranceProvider",
                      { required: true }
                    ),
                  }}
                  checked={
                    formData.canCOCManagementContatctTheAssuranceProvider ===
                    false
                  }
                  value={false}
                  name="canCOCManagementContatctTheAssuranceProvider"
                />
                <label className="capitalize text-sm" htmlFor="">
                  No
                </label>
              </div>
              {errors.canCOCManagementContatctTheAssuranceProvider &&
                errors.canCOCManagementContatctTheAssuranceProvider.type ===
                  "required" && (
                  <Error
                    key={errorCount}
                    text={fieldRequiredMessage("This field")}
                  />
                )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ThirdStep;
