import ReactModal from "react-modal";
import SubmitButton from "../../../components/forms/Buttons/SubmitButton";
import TextInput from "../../../components/forms/TextInput";
import {
  fieldRequiredMessage,
  invalidWebsiteURLMessage,
} from "../../../errors/errors";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Error from "../../../components/Alert/Error";
import TextArea from "../../../components/forms/TextArea";
import SelectInput from "../../../components/forms/SelectInput";
import { formatDate, standardList, yearsList } from "../../../utils/utils";
import { validWebsite } from "../../../utils/validation";
import DownloadFile from "../../../components/certification-process/DownloadFile";
import { useDispatch, useSelector } from "react-redux";

export default function RetirementForm({
  isModalOpen,
  closeModal,
  certificationId,
  transactionId,
  transactionCarbonFootPrint,
  transactionToBeRetired,
  codeCertification,
  formData,
  setIsLineSaved,
  index = null,
  rowToRemove = null,
  setFormData,
}) {
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const [errorCount, setErrorCount] = useState(0);
  const [file, setFile] = useState();
  const [isProofRequired, setIsProofRequired] = useState(true);
  const [showErrorWebsite, setShowErrorWebsite] = useState(false);
  const [data, setData] = useState({});
  const [indexFound, setIndexFound] = useState(-1);
  const [proofOfRetirementValue, setProofOfRetirementValue] = useState(false);
  const [currentRowToRemove, setRowToRemove] = useState();

  const ccrData = useSelector((state) => state.certificationStep.ccr);
  const dispatch = useDispatch();

  useEffect(() => {
    setData(null);
    if (rowToRemove !== null) {
      setRowToRemove(currentRowToRemove);
    }
    if (ccrData && ccrData.certificationId === certificationId) {
      setData(ccrData);
      setFile(ccrData.attachments.length > 0 && ccrData.attachments[0]);
      if (ccrData.attachments.length > 0) {
        setIsProofRequired(false);
      }
    }
    if (index !== null) {
      // only splice array when item is found
      setIndexFound(formData.indexOf(index));
      setData(index);
      setValue("standard", index.standard);
      setValue("vintageYear", new Date(index.vintageYear).getFullYear());
      setValue("quantity", index.quantity);
      setValue("idRetirement", index.idRetirement);
      setValue("serialNumbers", index.serialNumbers);
      setValue("retirementDate", formatDate(index.retirementDate));
      setValue("proofOfRetirement", index.proofOfRetirement);
      setValue(
        "projectDescriptionAndLocation",
        index.projectDescriptionAndLocation
      );
      index?.files !== null ? setFile(index?.files) : setFile(null);
      if (index?.files !== null) setIsProofRequired(false);
    } else if (index === null) {
      setIndexFound(-1);
      reset();
      setFile(null);
      setData({});
    }
  }, [
    ccrData,
    ccrData?.certificationId,
    ccrData?.attachments,
    index,
    index?.vintageYear,
    index?.standard,
    index?.quantity,
    index?.idRetirement,
    index?.serialNumbers,
    index?.retirementDate,
    index?.proofOfRetirement,
    index?.projectDescriptionAndLocation,
    index?.files,
    rowToRemove,
  ]);

  useEffect(() => {
    if (rowToRemove !== null) {
      const index = formData.indexOf(rowToRemove);

      const newFormData = formData
        .slice(0, index)
        .concat(formData.slice(index + 1));
      ///formData.splice(index, 1);
      setFormData(newFormData);
      dispatch({ type: "SET_RETIREMENT_DATA", payload: newFormData });
      setIsLineSaved(true);
      setRowToRemove(null);
    }
  }, [dispatch, rowToRemove]);

  const isValidWebsite = (websiteUrl) => {
    const valid = validWebsite.exec(websiteUrl);
    return valid ? true : false;
  };

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    if (name === "Files") {
      if (value !== undefined && value !== null && value !== "") {
        setIsProofRequired(false);
      } else setIsProofRequired(true);
      const file = e.target.files;
      setFile(file[0]);
    } else {
      if (name === "proofOfRetirement" && value !== "" && value !== undefined) {
        setProofOfRetirementValue(true);
        setValue("proofOfRetirement", value);

        const validWebsite = isValidWebsite(value);
        if (validWebsite) {
          setIsProofRequired(false);
        }
        setShowErrorWebsite(!validWebsite);
      } else if (
        name === "proofOfRetirement" &&
        (value === "" || value === null || value === undefined)
      ) {
        setShowErrorWebsite(true);
        setIsProofRequired(true);
      }
    }
  };

  const onSubmitForm = (dataa) => {
    const newData = {
      transactionId: transactionId,
      transactionCarbonFootPrint: transactionCarbonFootPrint,
      transactionToBeRetired: transactionToBeRetired,
      isValidated: false,
      isSentToCoc: true,
      standard: dataa.standard,
      vintageYear: new Date(`${dataa.vintageYear}-01-01`).toISOString(),
      serialNumbers: dataa.serialNumbers,
      quantity: parseInt(dataa.quantity),
      idRetirement: dataa.idRetirement,
      stepName: "CarbonCreditsRetirements",
      processStep: 3,
      currentCertificationStatusName: "Pending",
      currentStatus: 3,
      codeCertification: codeCertification,
      projectDescriptionAndLocation: dataa.projectDescriptionAndLocation,
      retirementDate: new Date(dataa.retirementDate).toISOString(),
      proofOfRetirement: proofOfRetirementValue ? dataa.proofOfRetirement : "",
      certificationId: certificationId,
      files: file ? file : null,
      fileData: null,
      attachments: data?.attachments ? data?.attachments : [],
    };
    if (indexFound > -1) {
      // if (data?.attachments.length > 0) {
      //   newData.files = base64toFile(
      //     data?.attachments[0].fileData,
      //     data?.attachments[0].fileName,
      //     getMimeTypeFromFileName(data?.attachments[0].fileName)
      //   );
      // }
      newData.id = data?.id;
      if (data?.proofOfRetirement) {
        newData.proofOfRetirement = data?.proofOfRetirement;
      }
      formData[indexFound] = newData;
      dispatch({ type: "SET_RETIREMENT_DATA", payload: [...formData] });
    } else {
      dispatch({
        type: "SET_RETIREMENT_DATA",
        payload: [...formData, newData],
      });
    }

    setIsLineSaved(true);
    setFile(null);
    closeModal(false);
    setProofOfRetirementValue(false);
  };

  return (
    <>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="modall" // Ajoutez la classe "my-modal" à votre modal
        overlayClassName="modall-overlay"
      >
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="grid grid-cols-2">
            <div className="w-3/4 mt-3">
              <SelectInput
                options={standardList}
                placeholder="Standard"
                label="Standard"
                name="standard"
                id="standard"
                defaultValue={index !== null ? data?.standard : ""}
                register={{
                  ...register("standard", {
                    required: true,
                  }),
                }}
                onChange={(e) => setValue("standard", e.target.value)}
                //tabIndex={1}
              />
              {errors.standard && errors.standard.type === "required" && (
                <Error
                  key={errorCount}
                  text={fieldRequiredMessage("Standard")}
                />
              )}

              <div className="mt-2">
                <TextInput
                  isNumberType={true}
                  type="text"
                  placeholder="Quantity"
                  label="Quantity"
                  name="quantity"
                  id="quantity"
                  register={{
                    ...register("quantity", {
                      required: true,
                    }),
                  }}
                  onChange={(e) => setValue("quantity", e.target.value)}
                  //tabIndex={3}
                  defaultValue={data?.quantity}
                />
                {errors.quantity && errors.quantity.type === "required" && (
                  <Error
                    key={errorCount}
                    text={fieldRequiredMessage("Quantity")}
                  />
                )}
              </div>

              <div className="mt-2">
                <TextInput
                  type="text"
                  placeholder="Serial Number"
                  label="Serial Number"
                  name="serialNumbers"
                  id="serialNumbers"
                  register={{
                    ...register("serialNumbers", {
                      required: true,
                    }),
                  }}
                  onChange={(e) => setValue("serialNumbers", e.target.value)}
                  //tabIndex={5}
                  defaultValue={data?.serialNumbers}
                />
                {errors.serialNumbers &&
                  errors.serialNumbers.type === "required" && (
                    <Error
                      key={errorCount}
                      text={fieldRequiredMessage("Serial Number")}
                    />
                  )}
              </div>

              <div className="mt-2">
                <TextArea
                  type="text"
                  placeholder="Description"
                  label="Description"
                  name="projectDescriptionAndLocation"
                  id="projectDescriptionAndLocation"
                  register={{
                    ...register("projectDescriptionAndLocation", {
                      required: true,
                    }),
                  }}
                  onChange={(e) =>
                    setValue("projectDescriptionAndLocation", e.target.value)
                  }
                  //tabIndex={7}
                  defaultValue={
                    index !== null ? data?.projectDescriptionAndLocation : ""
                  }
                />
                {errors.projectDescriptionAndLocation &&
                  errors.projectDescriptionAndLocation.type === "required" && (
                    <Error
                      key={errorCount}
                      text={fieldRequiredMessage("Description")}
                    />
                  )}
              </div>
            </div>

            <div className="w-3/4 mt-3">
              <div>
                <SelectInput
                  options={yearsList}
                  placeholder="Vintage Year"
                  label="Vintage Year"
                  name="vintageYear"
                  id="vintageYear"
                  register={{
                    ...register("vintageYear", {
                      required: true,
                    }),
                  }}
                  onChange={(e) => setValue("vintageYear", e.target.value)}
                  //tabIndex={2}
                  defaultValue={
                    index !== null
                      ? new Date(data?.vintageYear).getFullYear()
                      : ""
                  }
                />
                {errors.vintageYear &&
                  errors.vintageYear.type === "required" && (
                    <Error
                      key={errorCount}
                      text={fieldRequiredMessage("Vintage Year")}
                    />
                  )}

                <div className="mt-2">
                  <TextInput
                    type="text"
                    placeholder="ID"
                    label="ID"
                    name="idRetirement"
                    id="idRetirement"
                    register={{
                      ...register("idRetirement", {
                        required: true,
                      }),
                    }}
                    onChange={(e) => setValue("idRetirement", e.target.value)}
                    //tabIndex={4}
                    defaultValue={data?.idRetirement}
                  />
                  {errors.idRetirement &&
                    errors.idRetirement.type === "required" && (
                      <Error
                        key={errorCount}
                        text={fieldRequiredMessage("ID")}
                      />
                    )}
                </div>

                <div className="mt-2">
                  <TextInput
                    type="date"
                    placeholder="Retirement Date"
                    label="Retirement Date"
                    name="retirementDate"
                    id="retirementDate"
                    register={{
                      ...register("retirementDate", {
                        required: true,
                        validate: (value) =>
                          new Date(value).getFullYear() >=
                          getValues().vintageYear,
                      }),
                    }}
                    onChange={(e) => setValue("retirementDate", e.target.value)}
                    //tabIndex={6}
                    defaultValue={formatDate(data?.retirementDate)}
                  />
                  {errors.retirementDate &&
                    errors.retirementDate.type === "required" && (
                      <Error
                        key={errorCount}
                        text={fieldRequiredMessage("Retirement Date")}
                      />
                    )}
                  {errors.retirementDate &&
                    errors.retirementDate.type === "validate" && (
                      <Error
                        key={errorCount}
                        text={
                          "Retirement Date must be earlier than the Vintage Year."
                        }
                      />
                    )}
                </div>

                <div className="mt-2">
                  <TextInput
                    type="text"
                    placeholder="copy/paste the link to the registry page proving carbon credits’ retirement"
                    label="Proof of retirement"
                    name="proofOfRetirement"
                    id="proofOfRetirement"
                    register={{
                      ...register("proofOfRetirement", {
                        required:
                          data?.attachments?.length > 0
                            ? false
                            : isProofRequired,
                        pattern: validWebsite,
                      }),
                    }}
                    onChange={handleChangeValue}
                    //tabIndex={8}
                    defaultValue={
                      data?.proofOfRetirement !== undefined
                        ? data?.proofOfRetirement
                        : ""
                    }
                  />
                  {errors.proofOfRetirement &&
                    errors.proofOfRetirement.type === "required" && (
                      <Error
                        key={errorCount}
                        text={fieldRequiredMessage("Proof of Retirement")}
                      />
                    )}
                  {errors.proofOfRetirement &&
                    errors.proofOfRetirement.type === "pattern" && (
                      <Error key={errorCount} text={invalidWebsiteURLMessage} />
                    )}
                  {showErrorWebsite && (
                    <Error key={errorCount} text={invalidWebsiteURLMessage} />
                  )}
                </div>

                <div className="mt-2">
                  <div className="upload relative overflow-hidden w-full mb-4 hover:cursor-pointer mt-3">
                    <button
                      className="hover:cursor-pointer login flex justify-center items-center text-mg border-0 bg-coc-blue rounded-lg hover:bg-[#329940] text-white w-full py-3"
                      //disabled={ccrData?.isSentToCoc || currentRowSaved}
                    >
                      <img
                        src="/assets/icons/upload.svg"
                        alt=""
                        className="upload mr-2 w-4 h-4"
                      />
                      Upload proof
                    </button>
                    <input
                      className="hover:cursor-pointer absolute right-0 bottom-0 top-0 left-0 opacity-0"
                      type="file"
                      name="Files"
                      id="File"
                      // {...register("Files", { required: true })}
                      onChange={handleChangeValue}
                      //tabIndex={10}
                      //disabled={currentRowSaved || ccrData?.isSentToCoc}
                    />
                    {errors.Files && errors.Files.type === "required" && (
                      <Error
                        key={errorCount}
                        text={fieldRequiredMessage("Files")}
                      />
                    )}
                    <span className="text-[9px] text-red-500">
                      ** You can only upload one file per retirement
                    </span>
                  </div>
                  <div>
                    <ul className="divide-y-2 divide-gray-300">
                      {(file !== null && (
                        <li
                          key={file?.name ? file?.name : file?.fileName}
                          className="py-2 flex justify-between"
                        >
                          <DownloadFile file={file} />
                        </li>
                      )) ||
                        (data && data?.files !== null && (
                          <DownloadFile file={data?.files} />
                        )) ||
                        (data && data?.attachments.length > 0 && (
                          <DownloadFile file={data?.attachments[0]} />
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center gap-x-2 py-3 px-4 bg-gray-50 border-t">
            <button
              type="button"
              className="py-2.5 px-4 inline-flex justify-center bg-gray-400 items-center gap-2 rounded-md border font-medium text-white shadow-sm align-middle hover:bg-gray-300 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
              //data-hs-overlay="#hs-user-details"
              onClick={() => closeModal(false)}
            >
              Cancel
            </button>
            <SubmitButton
              className="py-2.5 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-medium bg-coc-green text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-coc-green focus:ring-offset-2 transition-all text-sm"
              onClick={() => setErrorCount(errorCount + 1)}
              //tabIndex={9}
              buttonText="Save"
            />
          </div>
        </form>
        <button
          type="button"
          className="modal-close-button close-button-right"
          onClick={() => closeModal(false)}
        >
          <svg
            className="w-3.5 h-3.5"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </ReactModal>
    </>
  );
}
