import { useState } from "react";
import LabelInput from "./Label";
import { useEffect } from "react";

export default function SelectInput(props) {
  const [inputValue, setInputValue] = useState();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (props.options) {
      setOptions(props.options);
    }
  }, [props.options]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  };
  const [naChecked, setNaChecked] = useState(false);
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }

    const keyValue = e.key;

    if (
      props.isNumberType &&
      !/^[0-9\s]+$/.test(keyValue) &&
      !["Backspace", "Delete"].includes(keyValue) &&
      keyValue !== "Tab"
    ) {
      e.preventDefault();
    }

    // Additional check for date input
    if (props.type === "date" && e.key === "Tab") {
      e.preventDefault();
      const formElements = document.querySelectorAll(
        "input, select, textarea, button"
      );
      const currentIndex = Array.from(formElements).findIndex(
        (el) => el === e.target
      );
      const nextElement = formElements[currentIndex + 1];
      if (nextElement) {
        nextElement.focus();
      }
    }
  };

  useEffect(() => {
    if (props.defaultValue) {
      setInputValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  return (
    <div className="input flex flex-col relative">
      <p className="font-bold text-left text-[#3D4196] text-lg mb-2">
        {props.title}
      </p>
      <LabelInput label={props.label} htmlFor={props.for} />
      <select
        className={`${
          props.className ||
          "p-0 border-b-2 border-[#dedede] border-x-0 border-t-0 focus:ring-0 w-full focus:border-b-[#3BB34B] focus:outline-none focus:border-b-2 h-[50px]"
        }`}
        name={props.name}
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
        {...props.register}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        autoComplete="nope"
        onClick={props.onClick}
        ref={props.ref}
        disabled={props.disabled}
        tabIndex={props.tabIndex}
        value={inputValue}
      >
        <option value="">Select an option</option>
        {options?.map((element) => (
          <option key={element} value={element}>
            {element}
          </option>
        ))}
      </select>
    </div>
  );
}
