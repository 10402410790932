import DownloadFile from "../../../components/certification-process/DownloadFile";
import { formatDate, standardList, yearsList } from "../../../utils/utils";
import { Tooltip } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CertificationFormModal from "../../../components/Modals/CertificationFormModal";
import { validWebsite } from "../../../utils/validation";
import Error from "../../../components/Alert/Error";
import { invalidWebsiteURLMessage } from "../../../errors/errors";

export default function RetirementRow({
  ccrData = null,
  currentStep,
  userRole,
  setCurrentLine,
  setFormData,
  formData,
  ccrRefused,
  certificationId,
  transactionId,
  transactionCarbonFootPrint,
  transactionToBeRetired,
  codeCertification,
  setIsLineSaved,
}) {
  const initialDatas = {
    standard: "",
    vintageYear: "",
    quantity: "",
    idRetirement: "",
    serialNumbers: "",
    projectDescriptionAndLocation: "",
    retirementDate: "",
    proofOfRetirement: "",
    files: null,
    transactionId: transactionId,
    transactionCarbonFootPrint: transactionCarbonFootPrint,
    transactionToBeRetired: transactionToBeRetired,
    isValidated: false,
    isSentToCoc: true,
    stepName: "CarbonCreditsRetirements",
    processStep: 3,
    currentCertificationStatusName: "Pending",
    currentStatus: 3,
    codeCertification: codeCertification,
    certificationId: certificationId,
    fileData: null,
    attachments: [],
  };
  const [file, setFile] = useState();
  const [currentRowSaved, setCurrentRowSaved] = useState(false);
  const [data, setData] = useState(ccrData || initialDatas);
  const [hiddenRow, setHiddenRow] = useState(false);
  const [isProofRequired, setIsProofRequired] = useState(true);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [updateFormData, setUpdateFormData] = useState([]);
  const [showErrorWebsite, setShowErrorWebsite] = useState(true);

  const creditRetirementData = useSelector(
    (state) => state.creditRetirementFormDataReducer.formData
  );
  const addCreditRetirement = useSelector(
    (state) => state.addCreditRetirements.data
  );
  let ccrDataUpdated = useSelector(
    (state) => state.updateCreditRetirements.data
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      creditRetirementData?.length > 0 &&
      creditRetirementData[0].certificationId === certificationId
    ) {
      setUpdateFormData(creditRetirementData);
      //setFile(null);
    }
  }, [creditRetirementData, certificationId]);

  useEffect(() => {
    if (addCreditRetirement || ccrDataUpdated) {
      setFile(null);
    }
  }, [addCreditRetirement, ccrDataUpdated]);

  let conditionUpdate = ccrRefused
    ? !ccrRefused || userRole === "VAP"
    : userRole === "VAP" ||
      currentStep?.isValidated ||
      currentStep?.isSentToCoc;

  const handleSaveRow = (e) => {
    setIsLineSaved(true);
    setCurrentRowSaved(true);
    if (ccrRefused === true) {
      if (data?.id) {
        const updated = updateFormData.length > 0 ? updateFormData : formData;
        const updatedFormData = updated.filter((form) => form.id !== data?.id);
        dispatch({
          type: "SET_RETIREMENT_DATA",
          payload: [...updatedFormData, data],
        });
      } else {
        dispatch({ type: "SET_RETIREMENT_DATA", payload: [...formData, data] });
      }
    } else {
      dispatch({ type: "SET_RETIREMENT_DATA", payload: [...formData, data] });
    }
  };

  const handleConfirmRemove = (keepChanges) => {
    if (!keepChanges) {
      handleRemoveRow();
    }
    setShowRemoveModal(false);
  };

  const handleRemoveRow = () => {
    setIsLineSaved(true);
    if (ccrData?.id) {
      const updated = updateFormData.length > 0 ? updateFormData : formData;
      const updatedFormData = updated.filter((form) => form.id !== ccrData?.id);
      setFormData(updatedFormData);
      dispatch({ type: "SET_RETIREMENT_DATA", payload: updatedFormData });
      setHiddenRow(true);
    } else {
      setHiddenRow(true);
      const updated = updateFormData.length > 0 ? updateFormData : formData;
      const index = updated.indexOf(data);
      if (index > -1) {
        // only splice array when item is found
        updated.splice(index, 1); // 2nd parameter means remove one item only
      }
      //const updatedFormData = formData.splice()
      setFormData(updated);
      dispatch({ type: "SET_RETIREMENT_DATA", payload: updated });
    }
  };

  const isValidWebsite = (websiteUrl) => {
    const valid = validWebsite.exec(websiteUrl);
    return valid ? true : false;
  };
  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    if (name === "quantity")
      if (name === "File") {
        if (value !== undefined && value !== null && value !== "") {
          setIsProofRequired(false);
        } else setIsProofRequired(true);
        const file = e.target.files;
        ccrRefused
          ? setCurrentLine((prevLine) => ({
              ...prevLine,
              files: file[0],
              id: ccrData?.id,
            }))
          : setCurrentLine((prevLine) => ({
              ...prevLine,
              files: file[0],
            }));

        ccrRefused
          ? setData((prevData) => ({
              ...prevData,
              files: file[0],
              id: ccrData?.id,
            }))
          : setData((prevData) => ({
              ...prevData,
              files: file[0],
            }));
        setFile(file[0]);
      } else {
        if (
          name === "proofOfRetirement" &&
          value !== "" &&
          value !== undefined
        ) {
          const validWebsite = isValidWebsite(value);
          if (validWebsite) {
            setIsProofRequired(false);
          }
          setShowErrorWebsite(validWebsite);
        } else if (
          name === "proofOfRetirement" &&
          (value === "" || value === null || value === undefined)
        ) {
          setShowErrorWebsite(true);
          setIsProofRequired(true);
        }
        ccrRefused
          ? setCurrentLine((prevData) => ({
              ...prevData,
              [name]:
                name === "vintageYear" || name === "retirementDate"
                  ? value && new Date(value).toISOString()
                  : name === "quantity"
                  ? parseInt(value)
                  : value,
              id: ccrData?.id,
            }))
          : setCurrentLine((prevData) => ({
              ...prevData,
              [name]:
                name === "vintageYear" || name === "retirementDate"
                  ? value && new Date(value).toISOString()
                  : name === "quantity"
                  ? parseInt(value)
                  : value,
            }));

        ccrRefused
          ? setData((prevData) => ({
              ...prevData,
              [name]:
                name === "vintageYear" || name === "retirementDate"
                  ? value && new Date(value).toISOString()
                  : name === "quantity"
                  ? parseInt(value)
                  : value,
              id: ccrData?.id,
            }))
          : setData((prevData) => ({
              ...prevData,
              [name]:
                name === "vintageYear" || name === "retirementDate"
                  ? value && new Date(value).toISOString()
                  : name === "quantity"
                  ? parseInt(value)
                  : value,
            }));
      }
  };

  const allFieldSet = () => {
    if (
      data.standard !== initialDatas.standard &&
      data.idRetirement !== initialDatas.idRetirement &&
      data.projectDescriptionAndLocation !==
        initialDatas.projectDescriptionAndLocation &&
      data.quantity !== initialDatas.quantity &&
      data.quantity !== 0 &&
      !isNaN(data.quantity) &&
      data.retirementDate !== initialDatas.retirementDate &&
      (data.files !== initialDatas.files ||
        data.proofOfRetirement !== initialDatas.proofOfRetirement) &&
      data.serialNumbers !== initialDatas.serialNumbers &&
      data.vintageYear !== initialDatas.vintageYear &&
      showErrorWebsite
    ) {
      return true;
    }
    return false;
  };

  const onKeyDownOnDate = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }

    if (e.key === "Tab") {
      e.preventDefault();

      const formElements = document.querySelectorAll(
        "input, select, textarea, button"
      );
      const currentIndex = Array.from(formElements).findIndex(
        (el) => el === e.target
      );
      const nextElement = formElements[currentIndex + 1];
      if (nextElement) {
        nextElement.focus();
      }
    }
  };

  const handleEditRow = () => {
    setCurrentRowSaved(false);
  };

  return (
    <>
      <tr
        className={`border-b-2 border-grey py-2 align-top text-left text-sm retirement-row ${
          hiddenRow && "hidden retirement-row"
        }`}
      >
        <td className="w-1/7 pr-2 py-3">
          <select
            className={`bg-white rounded-sm w-full h-10 text-sm ${
              !ccrData && data?.standard === "" && "border-red-500"
            }`}
            name="standard"
            title="Verified Carbon Standard (Verra)"
            value={data?.standard}
            disabled={conditionUpdate || currentRowSaved}
            onChange={handleChangeValue}
          >
            <option value="">Select an option</option>
            {standardList.map((element) => (
              <option key={element}>{element}</option>
            ))}
          </select>
          {/* {!ccrData && data?.standard === "" && (
            <Error text={fieldRequiredMessage("Standard")} />
          )} */}
        </td>

        <td className="w-1/7 pr-2 py-3">
          <select
            className={`bg-white rounded-sm w-full h-10 text-sm ${
              !ccrData && data?.vintageYear === "" && "border-red-500"
            }`}
            name="vintageYear"
            value={new Date(data?.vintageYear).getFullYear()}
            disabled={conditionUpdate || currentRowSaved}
            onChange={handleChangeValue}
          >
            <option value="">Select an option</option>
            {yearsList().map((element) => (
              <option key={element}>{element}</option>
            ))}
          </select>
          {/* {!ccrData && data?.vintageYear === "" && (
            <Error text={fieldRequiredMessage("Vintage Year")} />
          )} */}
        </td>

        <td className="w-1/7 pr-2 py-3">
          <input
            className={`bg-white rounded-sm ${
              !ccrData && data?.quantity === "" && "border-red-500"
            }`}
            name="quantity"
            type="number"
            value={data?.quantity}
            disabled={conditionUpdate || currentRowSaved}
            onChange={handleChangeValue}
          />
          {/* {!ccrData && data?.quantity === "" && (
            <Error text={fieldRequiredMessage("Quantity")} />
          )} */}
        </td>

        <td className="w-1/7 pr-2 py-3">
          <input
            className={`bg-white rounded-sm ${
              !ccrData && data?.idRetirement === "" && "border-red-500"
            }`}
            name="idRetirement"
            type="text"
            value={data?.idRetirement}
            // {...register("idRetirement", { required: true })}
            disabled={conditionUpdate || currentRowSaved}
            onChange={handleChangeValue}
          />
          {/* {!ccrData && data?.idRetirement === "" && (
            <Error text={fieldRequiredMessage("Project ID")} />
          )} */}
        </td>
        <td className="max-w-xs pr-2 py-3">
          <input
            className={`bg-white rounded-sm ${
              !ccrData && data?.serialNumbers === "" && "border-red-500"
            }`}
            name="serialNumbers"
            type="text"
            value={data?.serialNumbers}
            // {...register("serialNumbers", { required: true })}
            disabled={conditionUpdate || currentRowSaved}
            onChange={handleChangeValue}
          />
          {/* {!ccrData && data?.serialNumbers === "" && (
            <Error text={fieldRequiredMessage("Serial Number")} />
          )} */}
        </td>
        <td className="w-1/7 pr-2 py-3">
          <input
            className={`bg-white rounded-sm ${
              !ccrData &&
              data?.projectDescriptionAndLocation === "" &&
              "border-red-500"
            }`}
            name="projectDescriptionAndLocation"
            type="text"
            value={data?.projectDescriptionAndLocation}
            // {...register("projectDescriptionAndLocation", {
            //   required: true,
            // })}
            disabled={conditionUpdate || currentRowSaved}
            onChange={handleChangeValue}
          />
          {/* {!ccrData && data?.projectDescriptionAndLocation === "" && (
            <Error text={fieldRequiredMessage("Description")} />
          )} */}
        </td>
        <td className="w-1/7 pr-2 py-3">
          <input
            className={`bg-white rounded-sm retirement text-sm px-0 ${
              !ccrData && data?.retirementDate === "" && "border-red-500"
            }`}
            name="retirementDate"
            type="date"
            value={formatDate(data?.retirementDate)}
            // {...register("retirementDate", { required: true })}
            disabled={conditionUpdate || currentRowSaved}
            onChange={handleChangeValue}
            onKeyDown={onKeyDownOnDate}
          />
          {/* {!ccrData && data?.retirementDate === "" && (
            <Error text={fieldRequiredMessage("Retirement Date")} />
          )} */}
        </td>

        <td className="w-1/7 pr-2 py-3">
          <Tooltip
            className="bg-[#707070] p-4"
            content={
              "copy/paste the link to the registry page proving carbon credits’ retirement"
            }
            placement="left"
          >
            <input
              className={`bg-white rounded-sm ${
                ((!ccrData && isProofRequired) ||
                  (ccrData?.proofOfRetirement === "" &&
                    ccrData?.attachments.length === 0)) &&
                "border-red-500"
              }`}
              name="proofOfRetirement"
              type="text"
              value={
                data?.proofOfRetirement !== "null" &&
                data?.proofOfRetirement !== null
                  ? data?.proofOfRetirement
                  : ""
              }
              // {...register("proofOfRetirement", {
              //   required: proofFiles ? false : true,
              //   pattern: validWebsite,
              // })}
              disabled={conditionUpdate || currentRowSaved}
              placeholder="copy/paste the link to the registry page proving carbon credits’ retirement"
              onChange={handleChangeValue}
            />
          </Tooltip>
          {!showErrorWebsite && <Error text={invalidWebsiteURLMessage} />}
          {/* {!ccrData && data?.proofOfRetirement === "" && data?.files === "" && (
            <Error text={fieldRequiredMessage("Proof of retirement")} />
          )} */}
          <div>
            <div className="upload relative overflow-hidden w-full mb-4 hover:cursor-pointer mt-3">
              <button
                className="hover:cursor-pointer login flex justify-center items-center text-mg border-0 bg-[#3BB34B] rounded-lg hover:bg-[#329940] text-white w-full py-3"
                disabled={ccrData?.isSentToCoc || currentRowSaved}
              >
                <img
                  src="/assets/icons/upload.svg"
                  alt=""
                  className="upload mr-2 w-4 h-4"
                />
                Upload proof
              </button>
              <input
                className="hover:cursor-pointer absolute right-0 bottom-0 top-0 left-0 opacity-0"
                type="file"
                name="File"
                id="File"
                // {...register("Files")}
                onChange={handleChangeValue}
                disabled={currentRowSaved || ccrData?.isSentToCoc}
              />
              <span className="text-[9px] text-red-500">
                ** You can only upload one file
                <br /> per retirement
              </span>
            </div>
            <div>
              <ul className="divide-y-2 divide-gray-300">
                {file ? (
                  <li key={0} className="py-2 flex justify-between">
                    <DownloadFile file={file} />
                  </li>
                ) : (
                  ccrData?.attachments.length > 0 &&
                  ccrData?.attachments.map((file, index) => (
                    <li key={index} className="py-2 flex justify-between">
                      <DownloadFile file={file} />
                    </li>
                  ))
                )}
                {/* {ccrData?.attachments.length > 0 &&
                  ccrData?.attachments.map((file, index) => (
                    <li key={index} className="py-2 flex justify-between">
                      <DownloadFile file={file} />
                    </li>
                  ))} */}
              </ul>
            </div>
          </div>
        </td>

        {(userRole === "Client" || userRole === "COC") &&
          !ccrData?.isSentToCoc && (
            <>
              <td className="text-center">
                {/* <Button
                                className={`bg-coc-green text-white px-3 py-2 text-center rounded-lg mt-3 mx-auto`}
                                type="button"
                                onClick={handleSaveRow}
                                text={`${currentRowSaved ? "Saved" : }`}
                                backgroundColor={`coc-green`}
                                hoverBgColor={`bg-[#329940]`}
                                disabled={currentRowSaved || (!ccrData && !allFieldSet())}
                              />
              */}
                {currentRowSaved === true && (
                  <>
                    <Tooltip
                      className="bg-[#707070] p-4"
                      content={
                        "This button allows you to edit a previously saved line."
                      }
                    >
                      <button
                        className={`bg-coc-green text-white px-3 py-2 text-center rounded-lg mt-[20px] mx-auto`}
                        onClick={handleEditRow}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                          <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                        </svg>
                      </button>
                    </Tooltip>
                  </>
                )}
                {currentRowSaved === false && (
                  <Tooltip
                    className="bg-[#707070] p-4"
                    content={"Please save the line before sending to COC."}
                  >
                    <button
                      className={`bg-coc-green text-white px-3 py-2 text-center rounded-lg mt-[20px]`}
                      disabled={!allFieldSet()}
                      //hidden={currentRowSaved}
                      onClick={handleSaveRow}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={`mx-auto w-5 h-5 text-center ${
                          (currentRowSaved || (!ccrData && !allFieldSet())) &&
                          "disabled-svg"
                        }`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3"
                        />
                      </svg>
                    </button>
                  </Tooltip>
                )}
              </td>

              <td className="text-center">
                <button
                  className={`bg-red-500 text-white px-3 py-2 text-center rounded-lg mt-[20px]`}
                >
                  <svg
                    onClick={() => setShowRemoveModal(true)}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 text-white mx-auto hover:cursor-pointer"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </td>
            </>
          )}
      </tr>
      <CertificationFormModal
        showConfirmationModal={showRemoveModal}
        handleConfirmationResponse={handleConfirmRemove}
        text={`Are you sure you want to remove this line ? This action is irreversible.`}
      />
    </>
  );
}
